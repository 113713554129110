@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

.page {
  height: 100vh;
  display: grid;
  user-select: none;
  background: #101012;
  place-items: center;
}

.container {
  padding: 50px;
  height: 720px;
  width: 900px;
  border-radius: 16px;
  background: linear-gradient(180deg, #26262A 0%, #161618 100%);
  box-shadow:
    0 8px 64px rgb(0 0 0 / 0.2),
    inset 0 1px 0 rgb(255 255 255 / 0.08);
}

.topControls {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.divider {
  margin: 0 -50px;
  box-shadow: 0 1px 0 rgb(255 255 255 / 0.1);
  border-bottom: 1px solid rgb(0 0 0 / 0.5);
}

.knobs {
  gap: 50px;
  display: flex;
}

.screen {
  width: 220px;
  height: 100px;
  position: relative;
  border-radius: 12px;
  background: rgb(255 255 255 / 0.08);
  box-shadow: 
    inset 0 12px 50px rgb(0 0 0 / 0.7),
    0 1px 0 rgb(255 255 255 / 0.08);
}

.screen::before {
  content: "";
  z-index: 1;
  inset: 10px;
  position: absolute;
  border-radius: 8px;
  background: rgb(0 0 0 / 0.4);
  box-shadow: 
    inset 0 0 50px rgb(0 0 0 / 0.4),
    inset 0 2px 12px rgb(0 0 0 / 0.6);
}

.screen::after {
  content: "";
  z-index: 2;
  inset: 20px;
  position: absolute;
  border-radius: 4px;
  background: linear-gradient(rgb(255 255 255 / 0.04), rgb(255 255 255 / 0) 20%);
}

.selectControls {
  gap: 30px;
  display: flex;
  padding-bottom: 30px;
}

.selectWrapper {
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.select {
  width: 180px;
  height: 32px;
  font-size: 13px;
  padding: 0 12px;
  position: relative;
  align-items: center;
  display: inline-flex;
  text-transform: uppercase;
  border-radius: 6px;
  background: #141417;
  cursor: default;
  color: #FFD3BA;
  font-weight: 600;
  text-shadow: 0 0 4px #B86F01;
  box-shadow: 0px -1px 0px rgba(255, 255, 255, 0.04), 0px 1px 0px rgba(255, 255, 255, 0.08), inset 0px 1px 2px rgba(0, 0, 0, 0.25), inset 0px 0px 40px #321500;
}

.selectLabel {
  opacity: 0.5;
  margin-right: 8px;
}

.select select {
  inset: 0;
  opacity: 0.00001;
  position: absolute;
}

.select::before {
  content: "";
  width: 0;
  height: 0;
  top: 10px;
  right: 12px;
  position: absolute;
  border-bottom: 4px solid #9C6140;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}

.select::after {
  content: "";
  width: 0;
  height: 0;
  bottom: 10px;
  right: 12px;
  position: absolute;
  border-top: 4px solid #9C6140;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}

.notes {
  gap: 30px;
  display: flex;
  min-height: 300px;
  position: relative;
  width: var(--width);
  flex-direction: column;
  justify-content: center;
}

.note {
  position: relative;
  width: var(--dot-size);
  height: var(--dot-size);
  border-radius: 50%;
  box-shadow: 
    inset 0 1px 0 rgb(255 255 255 / 0.4),
    0 0 32px rgb(235 152 28 / 0.6),
    0 0 2px rgb(0 0 0 / 0.9);
  background: radial-gradient(50% 50% at 50% 50%, #FFEBCE 0%, #FFA723 100%);
}

.noteRing {
  inset: 0;
  position: absolute;
  border-radius: 50%;
  border: 0.5px solid rgb(235 152 28 / 0.4);
}

.bar {
  top: 0;
  bottom: 0;
  position: absolute;
  width: var(--bar-width);
  background: rgb(235 152 28 / 0.04);
  border-left: 1px solid rgb(235 152 28 / 0.12);
  border-right: 1px solid rgb(235 152 28 / 0.12);
  left: calc((var(--width) / 2) - (var(--bar-width) / 2));
}

.barHit {
  top: 0;
  bottom: 0;
  position: absolute;
  width: var(--bar-width);
  background: rgb(235 152 28 / 0.02);
  border-left: 1px solid rgb(235 152 28 / 0.08);
  border-right: 1px solid rgb(235 152 28 / 0.08);
  left: calc((var(--width) / 2) - (var(--bar-width) / 2));
}

.knob {
  user-select: none;
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}

.knob input {
  position: absolute;
  pointer-events: none;
  opacity: 0.000001;
}

.knobSocket {
  width: 46px;
  height: 46px;
  padding: 3px;
  border-radius: 50%;
  margin-bottom: 16px;
  background: rgb(0 0 0 / 0.6);
  box-shadow: inset 0 0 0 1px rgb(255 255 255 / 0.12);
}

.knobControl {
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 50%;
  background: linear-gradient(180deg, #2F2F33 0%, #212124 100%);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5), 0px 12px 50px rgba(0, 0, 0, 1), inset 0px 1px 0px rgba(255, 255, 255, 0.08);
}

.knobRotation {
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 50%;
}

.knobRotation::before {
  top: 4px;
  width: 2px;
  left: 19px;
  content: "";
  height: 12px;
  background: white;
  position: absolute;
  border-radius: 1px;
}

.knobTick {
  inset: -12px;
  position: absolute;
  --color: #6D6973;
}

.knobTick::before {
  top: 0;
  width: 2px;
  left: 50%;
  content: "";
  height: 5px;
  position: absolute;
  border-radius: 1px;
  background: var(--color);
  transform: translateX(-50%);
}

.label {
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: rgb(255 255 255 / 0.7);
  text-shadow: 0 1px 0 rgb(255 255 255 / 0.1);
}

.gridContainer {
  padding: 10px;
  position: relative;
  border-radius: 12px;
  background: linear-gradient(180deg, #31241B 0%, #2F2D2C 100%);
  box-shadow: 0px -1px 0px rgba(255, 146, 85, 0.08), inset 0px -1px 0px rgba(255, 201, 152, 0.12), inset 0px 12px 50px rgba(0, 0, 0, 0.8);
}

.grid {
  gap: 5px;
  padding: 5px;
  display: flex;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  background: #0D0D0E;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 1px 0px rgba(255, 193, 158, 0.11), inset 0px 1px 8px rgba(0, 0, 0, 0.6), inset 0px 0px 150px rgba(162, 58, 0, 0.12);
}

.track {
  height: 25px;
  display: flex;
}

.trackNote {
  width: 50px;
  color: #FF9B83;
  font-size: 13px;
  font-weight: 500;
  border-radius: 8px;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  border: 2px solid #771700;
  text-shadow: 0 0 4px #E5502C;
  box-shadow: 
    0 0 4px #791800,
    inset 0 0 4px #791800;
  /* background: radial-gradient(83.33% 83.33% at 50% 50%, #E3792D 0%, #9E2600 100%); */
}

.trackBeats {
  width: 100%;
  display: flex;
}

.trackBeat {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  position: relative;
}

.trackDot {
  z-index: 2;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #7C3106;
}

.trackBeat[data-active=true] .trackDot {
  border-color: #BF591F;
}

.selector {
  inset: 0;
  z-index: 1;
  opacity: 0;
  position: absolute;
  border-radius: 8px;
  background: rgb(255 255 255 / 0.05);
}

.trackBeat:hover .selector {
  opacity: 1;
}

.leds {
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  justify-content: space-between;
  padding-left: 75px;
  padding-right: 25px;
}

.led {
  width: 20px;
  height: 10px;
  position: relative;
  border-radius: 20px;
  background: #131315;
  box-shadow: inset 0px -0.5px 0px rgba(255, 255, 255, 0.22);
}

.led::before {
  content: "";
  inset: 2px;
  position: absolute;
  border-radius: 4px;
  filter: drop-shadow(0px 0px 2px #000000);
  box-shadow: inset 0px 0.4px 0px rgba(255, 255, 255, 0.08);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%);
}

.led[data-active=true]::before {
  background: radial-gradient(66.67% 66.67% at 50% 50%, #FFD6BF 0%, #FF5C00 100%);
  box-shadow: 0px 0px 10px rgba(242, 177, 140, 0.25), 0px 0px 2px #000000, inset 0px 2px 4px rgba(255, 255, 255, 0.4), inset 0px 0.5px 0px rgba(255, 255, 255, 0.5);
}
