.page {
  width: 100vw;
  height: 100vh;
  display: grid;
  position: relative;
  place-items: center;
  background: linear-gradient(134.37deg, #1C1922 2.51%, #110E16 94.45%);
}

.container {
  z-index: 2;
  width: 500px;
  position: relative;
  padding: 60px 60px;
  border-radius: 20px;
  background: rgb(255 255 255 / 0.02);
  border: 1px solid rgb(255 255 255 / 0.05);
}

.list {
  color: white;
  position: relative;
}

.row {
  z-index: 2;
  cursor: default;
  padding: 12px 0;
  font-size: 14px;
  user-select: none;
  position: relative;
  border-bottom: 1px solid rgb(255 255 255 / 0.06);
}

.row[data-before-selection=true] {
  border-bottom-color: transparent;
}

.row[data-bottom=true] {
  border-bottom-color: transparent;
}

.row:last-child {
  border-bottom-color: transparent;
}

.selector {
  top: 0;
  bottom: 0;
  right: -40px;
  width: 40px;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: flex-end;
}

.checkbox {
  width: 20px;
  height: 20px;
  position: relative;
  border-radius: 50%;
  border: 2px solid rgb(255 255 255 / 0.15);
  opacity: 0;
  transition: all 200ms;
  transform: translateX(20px);
}

.checkbox::before {
  top: 3px;
  left: 3px;
  opacity: 0;
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  background: white;
}

.row:hover .checkbox,
.list[data-selecting=true] .checkbox {
  opacity: 1;
  transform: translateX(0);
}

.checkbox:hover {
  border-color: rgb(255 255 255 / 0.3);
}

.checkbox[data-checked=true] {
  opacity: 1;
  border-color: white;
  transform: translateX(0);
}

.checkbox[data-checked=true]::before {
  opacity: 1;
}

.selection {
  z-index: 1;
  left: -12px;
  right: -12px;
  height: 20px;
  position: absolute;
  border-radius: 12px;
  background: linear-gradient(135deg, rgb(255 255 255 / 0.04), rgb(255 255 255 / 0.05))
}
