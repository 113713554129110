.page {
  width: 100vw;
  height: 100vh;
  display: grid;
  position: relative;
  place-items: center;
  background: linear-gradient(132.01deg, #FAF8FF 1.04%, #E1DEE7 97.15%);
}

.island {
  top: 20px;
  padding: 5px;
  color: white;
  display: flex;
  position: fixed;
  min-height: 42px;
  align-items: center;
  justify-content: center;
  background: linear-gradient(94.8deg, #1B1921 16.38%, #110F1A 88.81%);
  box-shadow:
    0 1px 2px rgb(0 0 0 / 0.12),
    0 1px 4px rgb(0 0 0 / 0.12),
    0 2px 24px rgb(0 0 0 / 0.12);
}

.navigation {
  display: flex;
}

.navLink {
  color: white;
  cursor: pointer;
  height: 32px;
  padding: 0 16px;
  position: relative;
  align-items: center;
  display: inline-flex;
  color: rgb(255 255 255 / 0.75);
}

.navLink:hover {
  color: white;
}

.navLink[data-active=true] {
  color: white;
}

.indicator {
  inset: 0;
  position: absolute;
  border-radius: 40px;
  background: rgb(255 255 255 / 0.15);
}

.navLink {
  display: inline-flex;
  align-items: center;
}

.icon {
  display: grid;
  place-items: center;
}

.navLink svg {
  width: 20px;
  height: 20px;
  line-height: 1;
}

.notification {
  gap: 12px;
  padding: 5px;
  display: flex;
  line-height: 1;
  font-size: 15px;
  margin-top: -1px;
  align-items: center;
  padding-right: 20px;
}

.notificationTitle {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
}

.notificationText {
  font-size: 13px;
  color: rgb(255 255 255 / 0.6);
}

.avatar {
  width: 35px;
  height: 35px;
  background: white;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
}

.search {
  gap: 12px;
  display: flex;
  align-items: center;
}

.search .icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: rgb(255 255 255 / 0.12);
}

.search svg {
  width: 20px;
  height: 20px;
}

.search input {
  border: none;
  color: white;
  font-size: 15px;
  width: 250px;
  background: transparent;
}

.message {
  border-radius: 18px;
  background: rgb(255 255 255 / 0.12);
}

.message p {
  padding: 5px;
}

.button {
  cursor: pointer;
  font-size: 15px;
  appearance: none;
  font-weight: 500;
  padding: 10px 15px;
  border-radius: 40px;
  background: transparent;
  border: 1px solid rgb(0 0 0 / 0.12);
}
