.page {
  width: 100vw;
  height: 100vh;
  background: black; 
  display: flex;
  flex-direction: column;
}

.split {
  height: 100%;
  color: white;
  border: 1px solid white;
}
