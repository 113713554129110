.switcher {
  top: 0;
  left: 0;
  bottom: 0;
  gap: 10px;
  z-index: 5;
  color: white;
  padding: 20px;
  display: flex;
  position: fixed;
  width: 140px;
  flex-direction: column;
  justify-content: center;
}

.task {
  width: 100px;
  height: 80px;
  display: grid;
  font-size: 13px;
  cursor: default;
  place-items: center;
  border-radius: 8px;
  background: #222;
  color: rgb(255 255 255 / 0.7);
  border: 1px solid rgb(255 255 255 / 0.04);
}

.task[data-active=true] {
  border-color: rgb(255 255 255 / 0.15);
  box-shadow: 0 2px 8px rgb(255 255 255 / 0.04);
}

.app {
  inset: 10px;
  z-index: 1;
  color: white;
  display: flex;
  position: fixed;
  border-radius: 12px;
  border: 1px solid rgb(255 255 255 / 0.08);
  background: rgb(255 255 255 / 0.02);
}

.sidebar {
  height: 100%;
  width: 280px;
  border-right: 1px solid rgb(255 255 255 / 0.08);
}

.sidebarItem {
  padding: 15px 15px;
  border-bottom: 1px solid rgb(255 255 255 / 0.08);
}
