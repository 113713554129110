.page {
  inset: 0;
  gap: 10px;
  padding: 10px;
  color: white;
  display: flex;
  position: fixed;

  background:
    radial-gradient(110% 110% at 90% -55%, rgba(227, 123, 0, 0.05) 0%, rgba(230, 69, 0, 0) 100%),
    radial-gradient(110% 110% at 100% 140%, rgba(0, 159, 227, 0.02) 0%, rgba(0, 188, 230, 0) 100%) ,
    radial-gradient(90% 140% at -20% -25%, rgba(82, 27, 109, 0.08) 0%, rgba(71, 26, 93, 0) 100%),
    #120E14;
}

.frame {
  width: 100%;
  border-radius: 12px;
  border: 1px solid rgb(255 255 255 / 0.08);
  border-top-width: 32px;
}

.dragging {
  z-index: 10;
  position: fixed;
  padding: 8px 12px;
  border-radius: 12px;
  background: rgb(255 255 255 / 0.2);
}

.dropzone {
  top: 10px;
  z-index: 10;
  right: 10px;
  bottom: 10px;
  width: 200px;
  position: fixed;
}

.dropPreview {
  width: 80px;
  height: 100%;
  border-radius: 12px;
  border: 1px solid rgb(255 255 255 / 0.08);
}
