.page {
  inset: 0;
  display: grid;
  position: fixed;
  place-items: center;
background: linear-gradient(127.09deg, #0F0627 2.14%, #0F0F17 25.23%, #130F0A 68.59%, #1F1230 100.64%);
}

.page::before {
  inset: 0;
  content: "";
  position: absolute;
  background-size: 40px 40px;
  background-position: center;
  background-image: radial-gradient(circle, rgb(255 255 255 / 0.08) 1px, transparent 1px);
}

.card {
  z-index: 2;
  padding: 1px;
  width: 400px;
  height: 400px;
  overflow: hidden;
  position: absolute;
  border-radius: 2px;
  background: linear-gradient(157.68deg, #4C2BD0 -8.82%, #8822AC 109.81%);
  opacity: 0;
  animation: 1s card 0.5s forwards;
  box-shadow: 0 20px 80px rgb(0 0 0 / 0.1);
}

@keyframes card {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.card::before {
  top: -200px;
  left: -200px;
  z-index: 1;
  content: "";
  width: 400px;
  height: 400px;
  position: absolute;
  background: radial-gradient(rgb(255 255 255 / 0.7), rgb(255 255 255 / 0) 60%);
  animation: shine 4s infinite linear;
}

@keyframes shine {
  0% {
    transform: translate(0, 0);
  }

  25% {
    transform: translate(100%, 0);
  }

  50% {
    transform: translate(100%, 100%);
  }

  75% {
    transform: translate(0, 100%);
  }

  100% {
    transform: translate(0, 0);
  }
}

.cardContents {
  z-index: 2;
  height: 100%;
  position: relative;
  background: #131117;
}

.light {
  opacity: 0;
  background-position: 0 0;
  background-repeat: no-repeat;
}

.light[data-light="1"],
.light[data-light="2"] {
  z-index: 4;
  height: 1px;
  width: 800px;
  position: absolute;
}

.light[data-light="3"],
.light[data-light="4"] {
  z-index: 4;
  height: 800px;
  width: 1px;
  display: block;
  position: absolute;
}

.light[data-light="1"] {
  transform: translate(0, -200px);
  background-image: linear-gradient(90deg, rgb(255 255 255 / 0) 0%, rgb(255 255 255 / 0.4) 15%, rgb(255 255 255 / 0) 25%);
  animation: 0.5s light1 0.5s forwards;
}

@keyframes light1 {
  from {
    opacity: 1;
    background-position: 0 0;
  }

  to {
    opacity: 0;
    background-position: 600px 0;
  }
}

.light[data-light="2"] {
  transform: translate(0, 200px);
  background-image: linear-gradient(90deg, rgb(255 255 255 / .4) 0%, rgb(255 255 255 / 0) 15%, rgb(255 255 255 / 0) 16%);
  animation: 0.5s light2 0.6s forwards;
}

@keyframes light2 {
  from {
    opacity: 1;
    background-position: 600px 0;
  }

  to {
    opacity: 0;
    background-position: 0 0;
  }
}


.light[data-light="3"] {
  transform: translate(-200px, 0);
}


.light[data-light="3"] {
  transform: translate(200px, 0);
  background-image: linear-gradient(rgb(255 255 255 / .4) 0%, rgb(255 255 255 / 0) 15%, rgb(255 255 255 / 0) 16%);
  animation: 0.5s light3 0.5s forwards;
}

@keyframes light3 {
  from {
    opacity: 1;
    background-position: 0 600px;
  }

  to {
    opacity: 0;
    background-position: 0 0px;
  }
}

.light[data-light="4"] {
  transform: translate(-200px, 0);
  background-image: linear-gradient(rgb(255 255 255 / .4) 0%, rgb(255 255 255 / 0) 15%, rgb(255 255 255 / 0) 16%);
  animation: 0.5s light4 0.5s forwards;
}

@keyframes light4 {
  from {
    opacity: 1;
    background-position: 0 0;
  }

  to {
    opacity: 0;
    background-position: 0 600px;
  }
}
