* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=Fira+Code&display=swap');

.page {
  z-index: 0;
  width: 100vw;
  height: 100vh;
  display: grid;
  position: relative;
  place-items: center;
  font-family: Inter;

  background:
    radial-gradient(110% 110% at 90% -55%, rgba(227, 123, 0, 0.05) 0%, rgba(230, 69, 0, 0) 100%),
    radial-gradient(110% 110% at 100% 140%, rgba(0, 159, 227, 0.02) 0%, rgba(0, 188, 230, 0) 100%) ,
    radial-gradient(90% 140% at -20% -25%, rgba(82, 27, 109, 0.08) 0%, rgba(71, 26, 93, 0) 100%),
    #120E14;
}

.kbd {
  color: white;
  font-size: 12px;
  padding: 8px 10px;
  border-radius: 8px;
  font-family: Inter, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  background: rgb(255 255 255 / 0.05);
  border: 1px solid rgb(255 255 255 / 0.12);
  text-shadow: 0 1px 0 rgb(0 0 0 / 0.4);
  animation: key 300ms forwards;
  box-shadow: 
    0 2px 8px rgb(0 0 0 / 0.2),
    inset 0 0 8px rgb(255 255 255 / 0.03);
}

.container {
  gap: 20px;
  width: 100%;
  display: flex;
  max-width: 500px;
  flex-direction: column;
}

.shortcutContainer {
  width: 100%;
  display: grid;
  height: 160px;
  user-select: none;
  position: relative;
  align-items: center;
  border-radius: 16px;
  flex-direction: column;
  justify-content: center;
  background: rgb(255 255 255 / 0.02);
  border: 1px solid rgb(255 255 255 / 0.1);
}

.progress {
  bottom: 12px;
  right: 12px;
  width: 15px;
  height: 15px;
  stroke: white;
  position: absolute;
}

.shortcut {
  gap: 8px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes key {
  from {
    background: rgb(255 255 255 / 0.5);
  }

  to {
    background: rgb(255 255 255 / 0.05);
  }
}

.hint {
  inset: 0;
  display: grid;
  position: absolute;
  place-items: center;
}

.action {
  left: 0;
  right: 0;
  top: 60px;
  font-size: 14px;
  text-align: center;
  position: absolute;
}

.shineText {
  --text: #B7ADBC;
  color: white;
  line-height: 1;
  font-size: 12px;
  text-align: center;
  background-color: var(--text);
  background-image: linear-gradient(95deg, var(--text) 0%, white 10%, var(--text) 20%);
  background-size: 200% 200%;
  background-repeat: no-repeat;
  background-position: 100%;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shimmer 2s ease-out infinite;
}

@keyframes shimmer {
  0% {
    background-position: 100%;
  }

  30%, 100% {
    background-position-x: -100%;
  }
}


.list {
  position: relative;
  border-radius: 16px;
  background: #1D191F;
  /* background: rgb(255 255 255 / 0.02); */
  /* border: 1px solid rgb(255 255 255 / 0.06); */
  box-shadow: 0 24px 100px rgb(0 0 0 / 0.1);
}

.list::before {
  content: "";
  inset: -1px;
  background: red;
  border-radius: 17px;
  z-index: -1;
  position: absolute;
  background: rgb(255 255 255 / 0.1);
}

.list::after {
  content: "";
  inset: -1px;
  border-radius: 17px;
  z-index: -1;
  position: absolute;
  background-repeat: no-repeat;
  background-image: linear-gradient(110deg, rgb(255 255 255 / 0) 10%, rgb(255 255 255 / 0.1) 20%, rgb(255 255 255 / 0) 30%);
  animation: shimmer 1s linear forwards;
}

@keyframes shimmer {
  from {
    background-position: -200px center;
  }

  to {
    background-position: 600px center;
  }
}

.listItem {
  height: 52px;
  display: flex;
  padding: 0 5px 0 15px;
  align-items: center;
  color: white;
  justify-content: space-between;
  border-bottom: 1px solid rgb(255 255 255 / 0.06);
}

.listItem:last-child {
  border-bottom: 0;
}

.listItem h4 {
  font-size: 13px;
  font-weight: 400;
  font-family: Inter;
}

.record {
  gap: 5px;
  font-size: 13px;
  cursor: default;
  padding: 5px;
  border-radius: 8px;
  display: inline-flex;
  background: transparent;
  color: rgb(255 255 255 / 0.5);
  border: 1px solid rgb(255 255 255 / 0)
}

.record[data-has-shortcut=false] {
  margin-right: 5px;
}

.recordLabel {
  padding: 0 4px;
}

.record .kbd {
  padding: 5px;
  font-size: 10px;
  min-width: 12px;
  border-radius: 6px;
}

.record:hover {
  border: 1px solid rgb(255 255 255 / 0.1)
}

.popover {
  height: 80px;
  width: 220px;
  padding: 12px;
  outline: none;
  border-radius: 12px;
  backdrop-filter: blur(20px);
  background: rgb(255 255 255 / 0.01);
  border: 1px solid rgb(255 255 255 / 0.08);
  box-shadow: 0 24px 32px rgb(0 0 0 / 0.2);
}
