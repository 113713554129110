.container {
  width: 100vw;
  height: 100vh;
  background: black;
}

.imageSelection {
  top: -100px;
  left: 0;
  z-index: 2;
  position: fixed;
  pointer-events: none;
  opacity: 0.0000009;
}
